<template>
  <el-container>
    <div class="loginmodal"></div>
    <el-row class="login-card">
      <el-col :xs="24" class="top-banner">
      </el-col>
      <el-col :xs="24" class="main">
        <el-row v-loading="isBusy">
          <el-col :xs="24" :md="10" class="left">
            <div class="leftmodal"></div>
            <div class="leftc">
              <div class="lmain">
                <img :src="webinfo && webinfo.logo ? webinfo.logo : '@/assets/logo.png'" class="logo"/>
                <div class="hosname">{{ webinfo && webinfo.name ? webinfo.name : '' }}</div>
              </div>
              <div class="lfooter">
                <el-dropdown @command="setLanguage" class="setlang">
                                    <span class="el-dropdown-link">
                                        {{ currLangText }}<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="(lang,lidx) in langlist"
                                      :key="lidx"
                                      :command="lang.value">
                      {{lang.label}}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :md="14" class="right">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item v-if="formtype=='login'"><a @click="formtype=true">{{ $t('login.Sign_In') }}</a>
              </el-breadcrumb-item>
              <el-breadcrumb-item v-else>{{ $t('login.Forgot_password') }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div v-if="formtype=='login'" class="loginform">
              <el-input :placeholder="$t('login.Account')"
                        suffix-icon="el-icon-user" v-model="loginData.account" @keyup.enter="dologin">
              </el-input>
              <el-input type="password" :placeholder="$t('login.Password')"
                        suffix-icon="el-icon-lock" v-model="loginData.password" @keyup.enter="dologin">
              </el-input>
              <el-input :placeholder="$t('login.Verification_code')" v-model="loginData.captcha" @keyup.enter="dologin">
                <img slot="suffix" crossOrigin="anonymous" v-bind:src="captchaimg" style="height: 90%;"
                     @click="changecaptcha"/>
              </el-input>
              <div class="forpass">
                <el-checkbox v-model="loginData.isRemember" style="color: #a6a9ad;" @change="changeRemember">
                  {{ $t('login.Remember_Me') }}
                </el-checkbox>
              </div>
              <el-row class="loginbtn">
                <el-button type="primary" class="el-col-sm-24 el-col-md-8" round plain :disabled="isBusy"
                           @click="dologin" :loading="isBusy">{{ $t('login.Sign_In') }}
                </el-button>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-footer class="up-footer">
      <div class="pos-bottom">© 2019-2020 Idongri All Rights Reserved.</div>
    </el-footer>
  </el-container>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      serdomain: '',
      wsdomain: '',
      serport: '80',
      // 登录信息
      loginData: {
        account: '',
        password: '',
        captcha: '',
        isRemember: false,
      },
      // 图标信息
      webinfo: {
        logo: require('../assets/logo.png'),
        name: '惠税通'
      },
      formtype: 'login',
      isBusy: false,
      captchaimg: null,
      timeInterval: null,
      currLang: 'cn',
      currLangText: 'English',
      langlist: [
        {
          value: 'cn',
          label: '简体中文',
        },
        {
          value: 'en',
          label: 'Englist',
        }
      ]
    };
  },
  watch: {
    'loginData.account': {
      handler(val, oldVal) {
        const isNotSymbolExp = /^[\u4E00-\u9FA5A-Za-z0-9]+$/;
        if (val && !isNotSymbolExp.test(val)) {
          this.loginData.account = oldVal;
        }
      },
    }
  },
  mounted() {
    this.setLanguage();
    this.changecaptcha();
    this.getRememberInfo();
    this.timeInterval = setInterval(this.changecaptcha, 300000);
  },
  methods: {
    setLanguage(e) {
      if (!e) {
        let e = this.$store.getters.getCurrLang || 'cn';
        let idx = this.langlist.findIndex((lang)=>{
          return lang.value==e;
        })
        if(idx!==-1){
          this.currLang = e;
          this.currLangText = this.langlist[idx].label;
        }
      } else {
        let idx = this.langlist.findIndex((lang)=>{
          return lang.value==e;
        })
        if(idx!==-1){
          this.currLang = e;
          this.currLangText = this.langlist[idx].label;
        }
        this.$store.commit('setCurrLang', e);
        this.$i18n.locale = e;
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    },
    showAlert(msg) {
      this.$message({
        showClose: true,
        message: msg,
        type: 'error',
        offset: 100
      });
    },
    changecaptcha() {
      this.captchaimg = process.env.VUE_APP_API_ROOT +
          '/system/login/captcha?__' + Math.random();
      this.loginData.captcha = '';
    },
    getRememberInfo() {
      const localStorage = window.localStorage;
      const rememberInfo = localStorage.getItem('REMLOGININFO');
      if (rememberInfo) {
        const rememberInfoObj = JSON.parse(rememberInfo);
        if (rememberInfoObj.isRemember) {
          this.loginData.account = rememberInfoObj.account;
          this.loginData.password = rememberInfoObj.password;
          this.loginData.isRemember = rememberInfoObj.isRemember;
        } else {
          this.setRememberInfo(false);
        }
      }
    },
    setRememberInfo(val) {
      const localStorage = window.localStorage;
      if (!val) {
        localStorage.removeItem('REMLOGININFO');
      } else {
        localStorage.setItem('REMLOGININFO', JSON.stringify(this.loginData));
      }
    },
    changeRemember() {
      if (!this.loginData.isRemember) {
        this.setRememberInfo(false);
      }
    },
    async dologin() {
      if (this.isBusy) return;
      if (!this.loginData.account) {
        this.showAlert(this.$t('login.enter_account'));
        return false;
      }
      if (!this.loginData.password) {
        this.showAlert(this.$t('login.enter_pass'));
        return false;
      }
      if (!this.loginData.captcha) {
        this.showAlert(this.$t('enter_code'));
        return false;
      }
      this.isBusy = true;
      let res = await this.httppost('/system/login/dologin?lang='+this.currLang, this.loginData);
      if (!res.code) {
        this.loginData.isRemember && this.setRememberInfo(true);
        this.$store.commit('setUserInfo', res.data.userinfo);
        this.$store.commit('setToken', res.data.token);
        //this.$store.dispatch('setMenus', res.data.menus);
        this.$store.dispatch('setPowerMap', res.data.accesslist);
        setTimeout(() => {
          this.isBusy = false;
          this.$router.push({path: '/home'}).catch(err => {err});
          // let firstrouter = this.getFirstRouter(res.data.menus);
          // this.$router.push({path: '/home'}).catch(err => {
          //   err
          // });
        }, 1500);
      } else {
        this.isBusy = false;
        this.showAlert(res.msg);
        this.changecaptcha();
      }
    },
    /*getFirstRouter(menus) {
      var firstrouter = '';
      for (var idx in menus) {
        let menu = menus[idx];
        if (menu.router != '') {
          return firstrouter = menu.router;
        } else if (typeof (menu.children) != "undefined" && menu.children) {
          return firstrouter = this.getFirstRouter(menu.children)
        } else {
          return firstrouter = '/home';
        }
      }
      return firstrouter;
    },*/
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
  }
}
</script>
<style lang="scss" scoped>
.el-container {
  height: 100%;
  background: url("../assets/img/login_bg.jpg") no-repeat center center fixed;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
}
</style>
<style lang="scss">
.loginmodal {
  background-color: rgba(54, 59, 70, .9);
  background-image: url('../assets/img/bg-dot.png');
  background-repeat: repeat;
  opacity: .9;
  filter: alpha(opacity=90);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  /*z-index: 1;*/
}

.login-card {
  max-width: 750px;
  margin: 3% auto;
  z-index: 2;
  background: transparent;

  .top-banner {
    margin-bottom: 5%;
    text-align: center;
    height: 5rem;

    img {
      height: 3.75rem;
    }
  }

  .main {
    background: #fff;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;

    .setdomain {
      padding: 40px;

      .portdiv {
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }

    .left {
      /*background: #409EFF;*/
      padding-top: 2.5rem;
      min-height: 25rem;
      position: relative;
      background: url("../assets/img/leftbg2.jpg") no-repeat center center fixed;
      background-size: cover;
      -webkit-font-smoothing: antialiased;

      .leftmodal {
        background: #409EFF;
        opacity: .9;
        filter: alpha(opacity=90);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        border-radius: 4px 0 0 4px;
        -webkit-border-radius: 4px 0 0 4px;
        -moz-border-radius: 4px 0 0 4px;
      }

      .leftc {
        position: relative;
        min-height: 25rem;
        z-index: 1;
        text-align: center;
      }

      .logo {
        width: 25%;
        /*border-radius: 50%;*/
        margin-top: 60px;
      }

      .hosname {
        font-size: 1.25rem;
        color: #fff;
        margin-top: 1.25rem;
        font-weight: bold;
      }

      .lfooter {
        width: 100%;
        position: absolute;
        bottom: 4rem;
        opacity: .8;
        -webkit-opacity: .8;
        -moz-opacity: .8;
        filter: alpha(opacity=80);

        a {
          color: #fff;
          width: 100%;
        }

        .setlang {
          margin-top: 15px;
          color: #fff;
        }
      }
    }

    .right {
      padding: 2.5rem;
      padding-bottom: 0rem;
      min-height: 25rem;

      .el-breadcrumb {
        font-size: 1.25rem;
        color: #888;
      }

      .loginform {
        padding-top: 1rem;
      }

      .el-input {
        margin-top: 1rem;

        .el-input__inner {
          border: none;
          border-bottom: 1px solid #DCDFE6;
          border-radius: 0;
          padding: 0.625rem 0;
        }
      }

      .el-select {
        width: 100%;
      }

      .forpass {
        padding: 1.25rem 0;
        text-align: left;
        color: #a6a9ad !important;
      }

      .loginbtn {
        padding: .25rem 0;
        text-align: left;
      }
    }
  }
}

.el-footer {
  padding: auto;
  background: 0;
  color: #fffFFF;
  opacity: .15;
  filter: alpha(opacity=15);
  z-index: 100000;
}

.up-footer {
  position: fixed;
  display: block;
  width: 100%;
  line-height: 60px;
  font-size: 12px;
  text-align: center;
  background: #000;
  bottom: 0;
  color: #FFFFFF;
  opacity: .15;
  filter: alpha(opacity=15);
}

.pos-bottom {
  opacity: 1;
}

@media screen and (max-width: 769px) {
  .login-card {
    max-width: 95%;
    margin: auto;

    .top-banner {
      display: none;
    }

    .main {
      .left {
        min-height: auto;
        padding: 0.875rem 1rem;

        .leftmodal {
          background: #409EFF;
          height: 100%;
        }

        .leftc {
          min-height: auto;
          text-align: left;

          .lmain {
            display: inline-block;
          }

          .logo {
            margin-left: 1rem;
            float: left;
          }

          .hosname {
            font-size: 2rem;
            padding-left: 0.5rem;
            padding-top: auto;
            float: left;
            /*margin-top: auto;*/
          }

          .lfooter {
            bottom: 0rem;
            text-align: right;
          }
        }

        .logo {
          margin-top: auto;
        }
      }

      .right {
        .loginbtn {
          padding: 1.25rem 0;
          text-align: left;

          .el-button {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>

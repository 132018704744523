import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { MessageBox } from 'element-ui';

axios.defaults.baseURL=process.env.VUE_APP_API_ROOT;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
axios.interceptors.request.use(
    config => {
        if (window.location.pathname !== '/login') {
            config.headers['Authorization']=`${store.getters.getToken}` + '=vueroute='+`${router.currentRoute.path}`;
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    });

const formatSuccessRes = (res) => {
    if(res.data.code<0){
        store.commit('setUserInfo', '');
        store.commit('setToken', '');
        store.dispatch('setPowerMap','');
        store.dispatch('setMenus','');
        console.log('res.data', res.data);
        axios.post('/system/errorlog/index', {datacontent: JSON.stringify(res.data)})
            .then(res => {}).catch(res => {});
        router.push({path: '/login'}).catch(err => {err});
        return false;
    }
    return {
        code: res.data.code,
        msg: res.data.msg,
        data: res.data.data,
        wait: res.data.wait?res.data.wait:3,
        time: res.data.time?res.data.time:''
    };
};

const formatErrorRes = (res, returnmsg) => {
    if(!returnmsg){
        MessageBox({
            showClose: true,
            message: 'Request error. Please check the network or contact the administrator',
            type: 'error',
            offset: 100
        });
    }
    return {
        code: res.status,
        msg: res.statusText,
        data: {}
    };
};

export const httpget = (url, params, isreturnmsg) => {
    return axios.get(url, {params: params})
        .then(res => {
        return formatSuccessRes(res);
    })
    .catch(res => {
        return formatErrorRes(res, isreturnmsg);
    });
};

export const httppost = (url, params, isreturnmsg) => {
    return axios.post(url, params)
        .then(res => {
        return formatSuccessRes(res);
    })
    .catch(res => {
        return formatErrorRes(res, isreturnmsg);
    });
};

export const httppatch = (url, params, isreturnmsg) => {
    return axios.patch(url, params)
        .then(res => {
            return formatSuccessRes(res);
        })
        .catch(res => {
            return formatErrorRes(res, isreturnmsg);
        });
};

export const httpput = (url, params, isreturnmsg) => {
    return axios.put(url, params)
        .then(res => {
            return formatSuccessRes(res);
        })
        .catch(res => {
            return formatErrorRes(res, isreturnmsg);
        });
};

export const httpdelete = (url, params,isreturnmsg) => {
    return axios.delete(url, params)
        .then(res => {
            return formatSuccessRes(res);
        })
        .catch(res => {
            return formatErrorRes(res,isreturnmsg);
        });
};

export const httpfile = (url, params,headers,isreturnmsg) => {
    let defaultheaders = {
        "Content-Type": "multipart/form-data",
        "Authorization":`${store.getters.getToken}` + '=vueroute='+`${router.currentRoute.path}`
    };
    if(headers){
        headers = Object.assign(defaultheaders, headers);
    }
    return axios.post(url, params,headers)
        .then(res => {
            return formatSuccessRes(res);
        })
        .catch(res => {
            return formatErrorRes(res,isreturnmsg);
        });
};

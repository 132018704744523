export const en = {
    addDepart: 'Add Depart',
    editDepart: 'Edit Depart',
    name: 'Depart',
    location : 'Depart Adr',
    locationx : 'Location X',
    locationy : 'Location Y',
    people : 'People',
    mobile : 'Mobile',
    remarks : 'Remarks',
    title: 'Depart Name',
    status: 'Status',
    province: 'Province',
}

export const cn = {
    addDepart: '添加分店',
    editDepart: '编辑分店',
    name: '分店',
    title: '分店名称',
    location : '所在地址',
    locationx : '经度',
    locationy : '纬度',
    people : '联系人',
    mobile : '联系手机',
    remarks : '分店备注',
    status: '显示状态',
    province: '所在省市',
}
export const en = {
    addFlash: 'Add Flash',
    editFlash: 'Edit Flash',
    flash: 'Flash',
    title: 'Title',
    url: 'Url',
    language: 'Language',
    chooselanguage: 'Please Choose Language',
    picture: 'Picture',
    sort: 'Sort',
    status: 'Status',
    created_at: 'Create Time',
}

export const cn = {
    addFlash: '添加幻灯片',
    editFlash: '编辑幻灯片',
    flash: '幻灯片',
    title: '幻灯片名称',
    url: '链接地址',
    language: '语言',
    chooselanguage: '请选择语言',
    picture: '缩略图',
    sort: '显示顺序',
    status: '显示状态',
    created_at: '添加时间',
}
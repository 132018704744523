import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index.vue'
import home from '@/views/home.vue'
import login from '@/views/login.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/',
    redirect: '/home',
    component: index,
    children: [
      {
        path: '/home',
        name: 'home',
        component: home
      },
      {
        path: '/system/node',
        name: 'node',
        component: () => import(/* webpackChunkName: "node" */ '@/views/system/node.vue')
      },
      {
        path: '/system/user',
        name: 'admin',
        component: () => import(/* webpackChunkName: "user" */ '@/views/system/user.vue')
      },
      {
        path: '/system/role',
        name: 'role',
        component: () => import(/* webpackChunkName: "role" */ '@/views/system/role.vue')
      },
      {
        path: '/system/optlog',
        name: 'optlog',
        component: () => import(/* webpackChunkName: "optlog" */ '@/views/system/optlog.vue')
      },
      {
        path: '/member/index',
        name: 'member',
        component: () => import(/* webpackChunkName: "member" */ '@/views/member/index.vue')
      },
      {
        path: '/question/index',
        name: 'question',
        component: () => import(/* webpackChunkName: "question" */ '@/views/question/index.vue')
      },
      {
        path: '/region/index',
        name: 'region',
        component: () => import(/* webpackChunkName: "region" */ '@/views/region/index.vue')
      },
      {
        path: '/person/index',
        name: 'person',
        component: () => import(/* webpackChunkName: "person" */ '@/views/person/index.vue')
      },
      {
        path: '/system/setting',
        name: 'setting',
        component: () => import(/* webpackChunkName: "setting" */ '@/views/system/setting.vue')
      },
      {
        path: '/address/index',
        name: 'address',
        component: () => import(/* webpackChunkName: "address" */ '@/views/address/index.vue')
      },
      {
        path: '/park/index',
        name: 'park',
        component: () => import(/* webpackChunkName: "park" */ '@/views/park/index.vue')
      },
      {
        path: '/category/index',
        name: 'category',
        component: () => import(/* webpackChunkName: "flash" */ '@/views/category/index.vue')
      },
      {
        path: '/depart/index',
        name: 'depart',
        component: () => import(/* webpackChunkName: "depart" */ '@/views/depart/index.vue')
      },
      {
        path: '/order/index',
        name: 'order',
        component: () => import(/* webpackChunkName: "order" */ '@/views/order/index.vue')
      },
      {
        path: '/suggest/index',
        name: 'suggest',
        component: () => import(/* webpackChunkName: "order" */ '@/views/suggest/index.vue')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router

export const en = {
    addNews: 'Add News',
    editNews: 'Edit News',
    name: 'News',
    title: 'Title',
    url: 'Url',
    picture: 'Picture',
    is_publish: 'Publish',
    sort: 'Sort',
    status: 'Status',
}

export const cn = {
    addNews: '添加新闻',
    editNews: '编辑新闻',
    name: '新闻',
    title: '新闻标题',
    url: '链接地址',
    picture: '新闻图片',
    is_publish: '是否发布',
    sort: '显示顺序',
    status: '显示状态',
}
export const en = {
    addFlash: 'Add Grade',
    editFlash: 'Edit Grade',
    title: 'Title',
    grade_name:'Grade',
    grade: 'Grade',
    sort: 'Sort',
    status: 'Status',
    created_at: 'Create Time',
}

export const cn = {
    addFlash: '添加等级',
    editFlash: '编辑等级',
    title: '等级名称',
    grade_name:'等级',
    grade: '会员等级',
    buy_price: '购买金额',
    sort: '显示顺序',
    status: '显示状态',
    created_at: '添加时间',
}
<template>
	<div id="app">
      <router-view/>
	</div>
</template>
<script>
export default {
	name: 'App',
	components: {}
}
</script>

<style>
body{
	height: 100%;
	margin: 0;
	padding: 0;
	background: #F2F2F2;
}
#app {
	font-family: 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/*color: #2c3e50;*/
	/*background: #f2f2f2;*/
	width: 100%;
	height: 100%;
}
</style>

export const en = {
    addaccount: 'Add Account',
    editaccount: 'Edit Account',
    account: 'Account',
    realname: 'Name',
    password: 'Password',
    mobile: 'Mobile',
    department: 'Depart',
    role: 'Role',
    last_login_time: 'Last login time',
    last_login_ip: 'Last login IP',
    enterdepart: 'Please choose depart',
    enterrole: 'Please choose role',
    enterdepartment: 'Please choose department',
    signature: 'Signature',

    addrole: 'Add Role',
    editrole: 'Edit Role',
    role_name: 'Role',
    is_system: 'Is System',
    privileges: 'Privileges',

}

export const cn = {
    addaccount: '添加账号',
    editaccount: '编辑账号',
    account: '账号',
    realname: '姓名',
    password: '密码',
    mobile: '手机号',
    department: '部门',
    role: '角色',
    last_login_time: '最后登录时间',
    last_login_ip: '最后登录IP',
    enterrole: '请选择角色',
    signature: '签名图',

    addrole: '添加角色',
    editrole: '编辑角色',
    role_name: '角色',
    is_system: '是否系统角色',
    privileges: '权限',
}
export const en = {
    created_at: 'Create Time'


}

export const cn = {
    created_at: '添加时间',
    front_domain: '前台域名',
    back_domain: '后台域名',
    back_title: '后台标题',
    merchants_title: '商户后台标题',
    ip_filter: '后台IP过滤',
    auto_audio: '自动审核',
    record_no: '备案号',
    service_line: '服务热线',
    official_qq: '官方QQ',
    service_qq: '客服QQ',
    first_point: '上级返点',
    second_point: '次级返点',
    three_point: '次次级返点',
    invited_money: '邀请奖励',
    recharge_service: '充值客服',
    first_cash_min_money: '首次最低提现金额',
    first_cash_max_money: '首次最高提现金额',
    cash_min_money: '最低提现金额',
    cash_max_money: '最高提现金额',
    cash_times: '提现次数',
    cash_service_charge: '提现手续费',
    reg_url: '注册地址',
    general_member_task_days: '普通会员任务天数',
    reg_init: '用户注册',
    credit_points_lt: '信用积分低于值',
    credit_points_task: '信用积分对应可领取任务数',
    credit_points_close: '封号信用积分',
    signin_push: '签到积分',
    first_win_push: '完成当日首次任务（加）',
    overdue_ded: '未在规定时间内完成任务（减）',
    dissatisfy_ded: '未按要求完成任务（减）',
}
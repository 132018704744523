import store from '@/store';

let wsConnection = {
    $ws: null,
    lockReturn: false,
    timeout: 50 * 1000, // 50秒一次心跳  服务器 判断大于55秒就当作客户端离线了
    timeoutObj: null,
    timeoutNum: null,
    serverTimeoutObj: null,
    //初始化webSocket长连接
    init: function (wsurl) {
        wsurl = wsurl ? wsurl : `${store.getters.getWsdomain}`;
        if(!wsurl) return false;
        this.$ws = new WebSocket(wsurl);//写入地址 这里的地址可以在init方法加入参数
        this.$ws.onopen = this.onOpen;
        this.$ws.onclose = this.onClose;
        this.$ws.onmessage = this.onMessage;
        this.$ws.onerror = this.onError;
    },
    //打开websocket
    onOpen(e) {
        //开始websocket心跳
        wsConnection.startWsHeartbeat();
    },
    onClose(e) {
        console.log('onClose',e, 'ws close');
    },
    onMessage(msg) {
        //每次接收到服务端消息后 重置websocket心跳
        wsConnection.resetHeartbeat();
        //服务端发送来的消息存到vuex
        window.console.log('原始返回:',msg);
        msg = msg.data;
        if(msg !='Timer' && msg!='connect success' && msg!='reloading...' && msg!='ok'){
            store.commit('setWsmsg', JSON.parse(msg));
        }
    },
    onError(err) {
        wsConnection.reconnect();
    },
    //重启websocket
    reconnect() {
        let _this = this;
        if (_this.lockReturn) {
            return;
        }
        _this.lockReturn = true;
        _this.timeoutNum && clearTimeout(_this.timeoutNum);
        _this.timeoutNum = setTimeout(function () {
            _this.init();
            _this.lockReturn = false;
        }, 3000);
    },
    startWsHeartbeat() {
        let _this = this;
        _this.timeoutObj && clearTimeout(_this.timeoutObj);
        _this.serverTimeoutObj && clearTimeout(_this.serverTimeoutObj);
        _this.timeoutObj = setInterval(function () {
            //判断websocket当前状态
            if (_this.$ws.readyState != 1) {
                _this.reconnect()
            }else{
                _this.send('Timer'); // 心跳是定时发送一些信息 不是说定时重连 判断如果关闭了才重连
            }
        }, _this.timeout);
    },
    //重置websocket心跳
    resetHeartbeat() {
        let _this = this;
        clearTimeout(_this.timeoutObj);
        clearTimeout(_this.serverTimeoutObj);
        _this.startWsHeartbeat()
    },
    // 实际调用的方法
    send(agentData, callback) {
        if (this.$ws.readyState === this.$ws.OPEN) {
            // 若是ws开启状态
            agentData = ''+JSON.stringify(agentData);
            this.$ws.send(agentData);
        } else if (this.$ws.readyState === this.$ws.CONNECTING) {
            // 若是 正在开启状态，则等待1s后重新调用
            let _this = this;
            setTimeout(function () {
                _this.send(agentData, callback)
            }, 1000)
        } else {
            // 若未开启 ，则等待1s后重新调用
            let _this = this;
            setTimeout(function () {
                _this.send(agentData, callback)
            }, 1000)
        }
    }
};

//抛出websocket对象
export default wsConnection

export const en = {
	port: 'Port (Default 80)',
	clear_cache: 'Clear Cache',
	Change_Server_Host: 'Change Server Host',
	Sign_In: 'Sign In',
	Forgot_password: 'Forgot password',
	Account: 'Account',
	Password: 'Password',
	Verification_code: 'Verification code',
	Remember_Me: 'Remember Me',
	enter_port: 'Please enter the port number',
	time_sub: 'Your local time and server time are not in the same time zone,Please set your computer time zone to Lagos time zone',
	adne: 'Account does not exist',
	enter_account: 'Please enter you account',
	enter_pass: 'Please enter you password',
	enter_code: 'Please enter the code',
}

export const cn = {
	port: '端口(默认 80)',
	clear_cache: '清理缓存',
	Change_Server_Host: '重置服务环境',
	Sign_In: '登 录',
	Forgot_password: '忘记密码',
	Account: '账号',
	Password: '密码',
	Verification_code: '验证码',
	Remember_Me: '记住登录状态',
	enter_port: '请输入端口号',
	time_sub: '您的电脑本地时间和服务器时间不一致,相差太大',
	adne: '账号不存在',
	enter_account: '请输入您的账号',
	enter_pass: '请输入账号对应的密码',
	enter_code: '请输入验证码',
}
export const en = {
	edit_pass: 'Eidt password',
	sign_out: 'Sign Out',
	dashboard: 'Dashboard',
	updating: 'The new version is updated...Please wait...',
	setsys: 'Please set up the system first',
	sureout: 'Are you sure to sign out?',
	update_error: 'Update error',
	reloadpage: 'This operation will reload the page, do you want to continue?',
	passconfirm: 'Confirm',
	oldpass: 'Old password',
	newpass: 'New password',
	enterconfirm: 'please input the confirm',
	confirmdiff: 'confirm is different from new password!',
	setprinter: 'Set Printer',
	general_printer: 'General purpose printer',
	no_general_printer :'No General purpose printer detected',
	label_printer: 'Label printer',
	no_label_printer: 'No label printer detected',
	thermal_printer: 'Thermal printer',
	no_thermal_printer: 'No Thermal printer detected',
	tosetprinter: 'To set up the printer',
	changeprinter: 'Change',
	enterprinter: 'Please select a printer',
	printers: 'Printers',
	no_printers: 'No printers detected',
}

export const cn = {
	edit_pass: '修改密码',
	sign_out: '退出登录',
	dashboard: '控制面板',
	updating: '正在更新版本...请耐心等待...',
	setsys: '请您先设置好系统，点击确定跳转到系统设置',
	sureout: '确定退出登录吗?',
	update_error: '更新出错',
	reloadpage: '这个操作会重新加载页面，确定继续吗?',
	passconfirm: '确认密码',
	oldpass: '原密码',
	newpass: '新密码',
	enterconfirm: '请再次输入密码确认',
	confirmdiff: '两次输入的密码不一致',
	setprinter: '设置打印机',
	general_printer: '通用打印机',
	no_general_printer :'没有设置通用打印机',
	label_printer: '标签打印机',
	no_label_printer: '没有设置标签打印机',
	thermal_printer: '小票打印机',
	no_thermal_printer: '没有设置小票打印机',
	tosetprinter: '设置',
	changeprinter: '重新设置',
	enterprinter: '请选择打印机',
	printers: '打印机',
	no_printers: '未设置打印机',
}
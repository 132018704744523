export const cn = {
    searchplaceholder : '客户账号',
    doall : '全部操作',
    search : '搜索',
    username : '用户名',
    time : '时间',
    manager : '操作类型',
    amount : '金额',
    begintime : '开始日期',
    begintoend: '至',
    endtime : '结束日期',
    oneweek : '最近一周',
    onemonth : '最近一个月',
    threemonth : '最近三个月',
    choosedata : '请选择需要操作的数据',

    //
    flushtime : '刷新时间',
    rechargetype : '手动/线上',
    manualtransfer : '手动转账',
    QRcodecollection : '二维码收款',
    onlinepayments : '在线支付',
    searchaccount : '账号',
    orderno : '订单号',
    allstatus : '全部状态',
    pending : '待处理',
    processing : '银行处理中',
    fail : '失败',
    success : '成功',
    rechargeamount : '充值金额',
    handlingfee : '手续费',
    rechargeuser : '充值用户',
    receiveaccount : '收款账户',
    postscript : '附言',
    rechargetime : '充值时间',
    dealpeople : '处理人',
    dealtime : '处理时间',
    audio:'去审核',
    rechargetitle : '充值信息',
    rechargetypes : '充值方式',
    channelcode : '充值渠道',
    audioprice : '审核金额',
    rechargeremarke : '充值说明',
    remarks : '失败理由之类的备注',
    inputfailremarks : '请输入失败理由备注',
    currentstatus : '当前状态',
    inputaudioprice : '请输入审核金额',

    //
    withd_account : '取款卡号',
    real_name : '取款姓名',
    account : '客户账号',
    audiofail : '不通过',
    audiosuccess : '通过',
    Refusepay : '拒绝支付',
    getmoneytime : '取款时间',
    charge_fee : '服务费',
    withd_name : '户名',
    bank_name : '取款银行',
    risk_account : '风险账号',
    grade_title : '会员等级',
    teller : '财务',
    memberaccount : '会员账号',
    warning : '审核提示',
    paycallback : '审核失败 支付接口返回',
    codecollectionqrcode : '收款二维码',
    getmoneypeople : '收款人',
    channel_name : '通道名称',
    open_bank_name : '开户行',
    thesechannel : '对应渠道',
    qrcode : '二维码',
    minprice : '最低收款额',
    bank : '银行',
    account_type : '账户类型',
    platform : '平台银行',
    language : '语言',
    banks_name : '银行名字',
    bank_code : '银行代码',
    bank_type : '银行类型',
    real_bank : '实体银行',
    online_bank : '电子银行/钱包',
    other_way : '其他',
    reson: '理由',
    manualedit: '手动加款/扣款',
    manuaccount: '充值账号', // 扣款加款那边用的
    amounttype: '金额类型', // 这个表示 充值余额还是可提现余额
    desamount: '充值余额',
    canamount: '可提现余额',
    mremarks: '加款/扣款理由之类的备注',
    yourpass: '您的账户密码',
    addmanual: '加款',
    submanual: '扣款',
}

export const en = {
    searchplaceholder : 'Account',
    doall : 'All Operations',
    search : 'Search',
    username : 'Username',
    time : 'Time',
    manager : 'Operation Type',
    amount : 'Amount',
    begintime : 'Start Date',
    begintoend: 'To',
    endtime : 'End Date',
    oneweek : '1 Week',
    onemonth : '1 Month',
    threemonth : '3 Months',
    choosedata : 'Please select operation data type',

    //
    flushtime : 'Refresh Time',
    rechargetype : 'Manual/Online',
    manualtransfer : 'Manual Transfer',
    QRcodecollection : 'QR Payment',
    onlinepayments : 'Online Payment',
    searchaccount : 'Account',
    orderno : 'Order No.',
    allstatus : 'All Status',
    pending : 'Pending',
    processing : 'Processing',
    fail : 'Fail',
    success : 'Success',
    rechargeamount : 'Deposit Amount',
    handlingfee : 'Processing Fee',
    rechargeuser : 'User Account',
    receiveaccount : 'Bank Account No',
    postscript : 'Remark',
    rechargetime : 'Deposit Time',
    dealpeople : 'Handler',
    dealtime : 'Processing Time',
    audio:'To Audit',
    rechargetitle : 'Deposit Info',
    rechargetypes : 'Deposit Type',
    channelcode : 'Deposit Channel',
    audioprice : 'Audit Amount',
    rechargeremarke : 'Deposit Instructions',
    remarks : 'Remarks such as the reason for failure',
    inputfailremarks : 'Please enter the failure reason remarks',
    currentstatus : 'Current Status',
    inputaudioprice : 'Please enter the audit amount',

    //
    withd_account : 'Withdrawal Account',
    real_name : 'User Real Name',
    account : 'User Account',
    audiofail : 'Fail',
    audiosuccess : 'Success',
    Refusepay : 'Rejected',
    getmoneytime : 'Withdrawal Time',
    charge_fee : 'Processing Fee',
    withd_name : 'Account Name',
    bank_name : 'Bank',
    risk_account : 'Risky Account',
    grade_title : 'Member Tier',
    teller : 'Finance',
    memberaccount : 'User Account',
    warning : 'Audit Tips',
    paycallback : 'Audit failed, payment interface returned',
    codecollectionqrcode : 'Payment Receiving QR Code',
    getmoneypeople : 'Payee',
    channel_name : 'Channel Name',
    open_bank_name : 'Bank Name',
    thesechannel : 'Corresponding Channel',
    qrcode : 'QR Code',
    minprice : 'Minimum Amount',
    bank : 'Bank',
    account_type : 'Account Type',
    platform : 'Platform Bank',
    language : 'Language',
    banks_name : 'Bank Name',
    bank_code : 'Bank Code',
    bank_type : 'Bank Type',
    real_bank : 'Physical Bank',
    online_bank : 'Online Banking/Wallet',
    other_way : 'Other',
    reson: 'Reason',
    manualedit: 'Manual Addition/Deduction',
    manuaccount: 'Deposit Account',
    amounttype: 'Amount Type',
    desamount: 'Deposit Amount',
    canamount: 'Withdrawable Balance',
    mremarks: 'Remarks such as the reason for addition/deduction',
    yourpass: 'Your account password',
    addmanual: 'Add',
    submanual: 'Deduct',
}

export const th = {
    searchplaceholder : 'บัญชี',
    doall : 'การดำเนินการทั้งหมด',
    search : 'ค้นหา',
    username : 'ชื่อผู้ใช้',
    time : 'เวลา',
    manager : 'ประเภทการทำงาน',
    amount : 'จำนวน',
    begintime : 'วันที่เริ่มต้น',
    begintoend: 'ถึง',
    endtime : 'วันที่สิ้นสุด',
    oneweek : '1 สัปดาห์',
    onemonth : '1 เดือน',
    threemonth : '3 เดือน',
    choosedata : 'โปรดเลือกประเภทข้อมูลการดำเนินการ',

    //
    flushtime : 'เวลารีเฟรช',
    rechargetype : 'คู่มือ/ออนไลน์',
    manualtransfer : 'โอนด้วยตนเอง',
    QRcodecollection : 'การชำระเงินด้วย QR',
    onlinepayments : 'การชำระเงินออนไลน์',
    searchaccount : 'บัญชีผู้ใช้',
    orderno : 'เลขที่ใบสั่งซื้อ',
    allstatus : 'สถานะทั้งหมด',
    pending : 'รอดำเนินการ',
    processing : 'กำลังประมวลผล',
    fail : 'ล้มเหลว',
    success : 'ประสบความสำเร็จ',
    rechargeamount : 'จำนวนเงินฝาก',
    handlingfee : 'ค่าธรรมเนียมการดำเนินการ',
    rechargeuser : 'บัญชีผู้ใช้',
    receiveaccount : 'หมายเลขบัญชีธนาคาร',
    postscript : 'บันทึก',
    rechargetime : 'เวลาฝาก',
    dealpeople : 'ตัวจัดการ',
    dealtime : 'ระยะเวลาดำเนินการ',
    audio:'เพื่อตรวจสอบ',
    rechargetitle : 'ข้อมูลการฝาก',
    rechargetypes : 'ประเภทเงินฝาก',
    channelcode : 'ช่องทางการฝาก',
    audioprice : 'จำนวนเงินที่ตรวจสอบ',
    rechargeremarke : 'คำแนะนำในการฝากเงิน',
    remarks : 'ข้อผิดพลาดเหตุผลของความล้มเหลว',
    inputfailremarks : 'โปรดป้อนหมายเหตุเหตุผลความล้มเหลว',
    currentstatus : 'สถานะปัจจุบัน',
    inputaudioprice : 'โปรดป้อนจำนวนเงินที่ตรวจสอบ',

    //
    withd_account : 'บัญชีถอน',
    real_name : 'ชื่อจริงของผู้ใช้',
    account : 'บัญชีผู้ใช้',
    audiofail : 'ล้มเหลว',
    audiosuccess : 'ประสบความสำเร็จ',
    Refusepay : 'ถูกปฏิเสธ',
    getmoneytime : 'เวลาถอน',
    charge_fee : 'ค่าธรรมเนียมการดำเนินการ',
    withd_name : 'ชื่อบัญชี',
    bank_name : 'ธนาคาร',
    risk_account : 'บัญชีที่มีความเสี่ยง',
    grade_title : 'ระดับสมาชิก',
    teller : 'การเงิน',
    memberaccount : 'บัญชีผู้ใช้',
    warning : 'เคล็ดลับการตรวจสอบ',
    paycallback : 'การตรวจสอบล้มเหลวส่งคืนอินเทอร์เฟซการชำระเงิน',
    codecollectionqrcode : 'รับชำระเงิน QR Code',
    getmoneypeople : 'ผู้รับเงิน',
    channel_name : 'ชื่อช่อง',
    open_bank_name : 'ชื่อธนาคาร',
    thesechannel : 'ช่องที่เกี่ยวข้อง',
    qrcode : 'รหัส QR',
    minprice : 'จำนวนเงินขั้นต่ำ',
    bank : 'ธนาคาร',
    account_type : 'ประเภทบัญชี',
    platform : 'แพลตฟอร์มธนาคาร',
    language : 'ภาษา',
    banks_name : 'ชื่อธนาคาร',
    bank_code : 'รหัสธนาคาร',
    bank_type : 'ประเภทธนาคาร',
    real_bank : 'ธนาคารทางกายภาพ',
    online_bank : 'ธนาคารออนไลน์/กระเป๋าเงิน',
    other_way : 'อื่น ๆ',
    reson: 'เหตุผล',
    manualedit: 'การเพิ่ม/หักด้วยตนเอง',
    manuaccount: 'บัญชีเงินฝาก',
    amounttype: 'ประเภทจำนวนเงิน',
    desamount: 'จำนวนเงินฝาก',
    canamount: 'ยอดเงินที่ถอนได้',
    mremarks: 'ข้อสังเกตเช่นเหตุผลในการบวก/หัก',
    yourpass: 'รหัสผ่านบัญชีของคุณ',
    addmanual: 'เพิ่ม',
    submanual: 'หัก',
}
export const cn = { // 中文
    member: '会员',
    addMember: '添加会员',
    editMember: '编辑会员',
    account: '账号',
    password: '密码',
    password_confirm: '重复密码',
    real_name:'实名',
    draw_password: '取款密码',
    grade: '等级',
    amount: '余额',
    online: '在离状态',
    invite: '上级',
    credit_value: '信用值',
    last_time: '最后登录时间',
    last_ip: '最后登录IP',
    is_lock: '锁定账号',
    is_risk: '风险账号',
    created_at: '注册时间',
    nick_name:'用户昵称',
    mobile:'联系电话',
    remark:'会员备注',
    lockaccount: '锁定账户',
    riskaccount: '风险账户',
    canwithd: '可提现', // 可提现金额
    addsub: '加款/扣款',
    nolock: '解除锁定',
    detail: '详情',
    choosegrade: '请选择会员等级',
    enterremark: '请输入备注',
    memdetail: '会员详情',
    fundlogs: '流水明细',
    opttype: '流水类型',
    fundamount: '流水金额',
    fcreated_at: '流水时间',
    passleng: '长度在 6 到 16 个字符',
    unlock: '未锁定',
    locked: '已锁定'
}

export const en = {  // 英文
    member: 'Member',
    addMember: 'Add Member',
    editMember: 'Edit Member',
    account: 'Account',
    password: 'Password',
    password_confirm: 'Confirm Password',
    real_name:'Real Name',
    draw_password: 'Fund Withdrawal Password',
    grade: 'Member Tier',
    amount: 'Amount',
    online: 'Status',
    invite: 'Upline',
    credit_value: 'Credit Value',
    last_time: 'Last Login Time',
    last_ip: 'Last Login IP',
    is_lock: 'Lock Account',
    is_risk: 'Risky Account',
    created_at: 'Registration Time',
    nick_name: 'User\'s Nickname',
    mobile:'Contact No.',
    remark:'Remarks',
    lockaccount: 'Lock Account',
    riskaccount: 'Risky Account',
    canwithd: 'Withdrawable Balance',
    addsub: 'Addition/Deduction',
    nolock: 'Unlock',
    detail: 'Details',
    choosegrade: 'Please select membership tier',
    enterremark: 'Please input remarks',
    memdetail: 'Member Details',
    fundlogs: 'Turnover Details',
    opttype: 'Turnover Category',
    fundamount: 'Turnover Amount',
    fcreated_at: 'Turnover Time',
    passleng: '6 to 16 characters in length',
    unlock: 'Not Locked',
    locked: 'Locked'
}

export const th = { //泰文
    member: 'สมาชิก',
    addMember: 'เพิ่มสมาชิก',
    editMember: 'แก้ไขสมาชิก',
    account: 'บัญชีผู้ใช้',
    password: 'รหัสผ่าน',
    password_confirm: 'ยืนยันรหัสผ่าน',
    real_name:'ชื่อจริง',
    draw_password: 'รหัสผ่านการถอนเงิน',
    grade: 'ระดับสมาชิก',
    amount: 'จำนวน',
    online: 'สถานะ',
    invite: 'อัพไลน์',
    credit_value: 'มูลค่าเครดิต',
    last_time: 'เวลาเข้าสู่ระบบครั้งล่าสุด',
    last_ip: 'IP เข้าสู่ระบบล่าสุด',
    is_lock: 'ล็อคบัญชี',
    is_risk: 'บัญชีที่มีความเสี่ยง',
    created_at: 'เวลาลงทะเบียน',
    nick_name: 'ชื่อเล่นของผู้ใช้',
    mobile:'หมายเลขโทรศัพท์',
    remark:'บันทึก',
    lockaccount: 'ล็อคบัญชี',
    riskaccount: 'บัญชีที่มีความเสี่ยง',
    canwithd: 'ยอดเงินที่ถอนได้',
    addsub: 'การบวก/การหัก',
    nolock: 'ปลดล็อค',
    detail: 'รายละเอียด',
    choosegrade: 'โปรดเลือกระดับสมาชิก',
    enterremark: 'กรุณาใส่หมายเหตุ',
    memdetail: 'รายละเอียดสมาชิก',
    fundlogs: 'รายละเอียดการหมุนเวียน',
    opttype: 'ประเภทการหมุนเวียน',
    fundamount: 'ยอดเงินหมุนเวียน',
    fcreated_at: 'เวลาหมุนเวียน',
    passleng: 'ความยาว 6 ถึง 16 อักขระ',
    unlock: 'ไม่ได้ล็อค',
    locked: 'ล็อก'
}